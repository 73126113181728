<template>
	<el-main class="bg">
		<el-form ref="form" :model="form" status-icon label-width="120px" v-if="is_view()">
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="通知序号" prop="notification_serial_number">
					<el-input id="notification_serial_number" v-model="form['notification_serial_number']" placeholder="请输入通知序号"
							  v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','notification_serial_number')) || (!form['notification_management_id'] && $check_field('add','notification_serial_number'))" :disabled="true"></el-input>
					<div v-else-if="$check_field('get','notification_serial_number')">{{form['notification_serial_number']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="日期" prop="date">
					<el-date-picker v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','date')) || (!form['notification_management_id'] && $check_field('add','date'))" id="date"
						v-model="form['date']" type="date" placeholder="选择日期">
					</el-date-picker>
					<div v-else-if="$check_field('get','date')">{{form['date']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="内容" prop="content">
					<el-input type="textarea" id="content" v-model="form['content']" placeholder="请输入内容"
						v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','content')) || (!form['notification_management_id'] && $check_field('add','content'))" :disabled="disabledObj['content_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','content')">{{form['content']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="类型" prop="type">
					<el-select id="type" v-model="form['type']"
						v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','type')) || (!form['notification_management_id'] && $check_field('add','type'))">
						<el-option v-for="o in list_type" :key="o" :label="o" :value="o">
						</el-option>
					</el-select>
					<div v-else-if="$check_field('get','type')">{{form['type']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="备注" prop="remarks">
					<el-input type="textarea" id="remarks" v-model="form['remarks']" placeholder="请输入备注"
						v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','remarks')) || (!form['notification_management_id'] && $check_field('add','remarks'))" :disabled="disabledObj['remarks_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','remarks')">{{form['remarks']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="开始时间" prop="start_time">
					<el-time-picker v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','start_time')) || (!form['notification_management_id'] && $check_field('add','start_time'))" id="start_time"
						v-model="form['start_time']" placeholder="选择时间">
					</el-time-picker>
					<div v-else-if="$check_field('get','start_time')">{{form['start_time']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="共计时" prop="co_timing">
					<el-input-number id="co_timing" v-model.number="form['co_timing']"
						v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','co_timing')) || (!form['notification_management_id'] && $check_field('add','co_timing'))"></el-input-number>
					<div v-else-if="$check_field('get','co_timing')">{{form['co_timing']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="停止时间" prop="end_time">
					<el-time-picker v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','end_time')) || (!form['notification_management_id'] && $check_field('add','end_time'))" id="end_time"
						v-model="form['end_time']" placeholder="选择时间">
					</el-time-picker>
					<div v-else-if="$check_field('get','end_time')">{{form['end_time']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="职工编号" prop="employee_number">
						<el-select v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','employee_number')) || (!form['notification_management_id'] && $check_field('add','employee_number'))" id="employee_number" v-model="form['employee_number']" :disabled="disabledObj['employee_number_isDisabled']">
							<el-option v-for="o in list_user_employee_number" :key="o['username']" :label="o['nickname'] + '-' + o['username']"
									   :value="o['user_id']">
							</el-option>
						</el-select>
						<el-select v-else-if="$check_field('get','employee_number')" id="employee_number" v-model="form['employee_number']" :disabled="true">
							<el-option v-for="o in list_user_employee_number" :key="o['username']" :label="o['nickname'] + '-' + o['username']"
									   :value="o['user_id']">
							</el-option>
						</el-select>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="订单编号" prop="order_number">
					<el-input id="order_number" v-model="form['order_number']" placeholder="请输入订单编号"
							  v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','order_number')) || (!form['notification_management_id'] && $check_field('add','order_number'))" :disabled="disabledObj['order_number_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','order_number')">{{form['order_number']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="商品名称" prop="trade_name">
					<el-input id="trade_name" v-model="form['trade_name']" placeholder="请输入商品名称"
							  v-if="user_group === '管理员' || (form['notification_management_id'] && $check_field('set','trade_name')) || (!form['notification_management_id'] && $check_field('add','trade_name'))" :disabled="disabledObj['trade_name_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','trade_name')">{{form['trade_name']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="审核状态" prop="examine_state">
					<el-select id="examine_state" v-model="form['examine_state']"
						v-if="user_group === '管理员' || (form['examine_state'] && $check_examine()) || (!form['examine_state'] && $check_examine())">
						<el-option key="未审核" label="未审核" value="未审核"></el-option>
						<el-option key="已通过" label="已通过" value="已通过"></el-option>
						<el-option key="未通过" label="未通过" value="未通过"></el-option>
					</el-select>
					<div v-else-if="$check_field('get','examine_state')">{{form["examine_state"]}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="审核回复" prop="examine_reply">
					<el-input id="examine_reply" v-model="form['examine_reply']" placeholder="请输入审核回复"
						v-if="user_group === '管理员' || (form['examine_reply'] && $check_examine()) || (!form['examine_reply'] && $check_examine())"></el-input>
					<div v-else-if="$check_field('get','examine_reply')">{{form["examine_reply"]}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item>
					<el-button type="primary" @click="submit()">提交</el-button>
					<el-button @click="cancel()">取消</el-button>
				</el-form-item>
			</el-col>

		</el-form>
	</el-main>
</template>

<script>
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		data() {
			return {
				field: "notification_management_id",
				url_add: "~/api/notification_management/add?",
				url_set: "~/api/notification_management/set?",
				url_get_obj: "~/api/notification_management/get_obj?",
				url_upload: "~/api/notification_management/upload?",

				query: {
					"notification_management_id": 0,
				},

				form: {
					"notification_serial_number":this.$get_stamp(), // 通知序号
					"date":'', // 日期
					"content":'', // 内容
					"type":'', // 类型
					"remarks":'', // 备注
					"start_time":'', // 开始时间
					"co_timing":0, // 共计时
					"end_time":'', // 停止时间
					"employee_number": 0, // 职工编号
					"order_number":'', // 订单编号
					"trade_name":'', // 商品名称
					"examine_state": "未审核",
					"examine_reply": "",
					"notification_management_id": 0 // ID

				},
				disabledObj:{
					"notification_serial_number_isDisabled": false,
					"date_isDisabled": false,
					"content_isDisabled": false,
					"type_isDisabled": false,
					"remarks_isDisabled": false,
					"start_time_isDisabled": false,
					"end_time_isDisabled": false,
					"order_number_isDisabled": false,
					"trade_name_isDisabled": false,
				},
				//类型选项列表
				list_type: ['轮牌','客点'],
				// 用户列表
				list_user_employee_number: [],

			}
		},
		methods: {
			/**
			 * 获取技师用户列表
			 */
			async get_list_user_employee_number() {
                // if(this.user_group !== "管理员" && this.form["employee_number"] === 0) {
                //     this.form["employee_number"] = this.user.user_id;
                // }
                var json = await this.$get("~/api/user/get_list?user_group=技师");
                if(json.result && json.result.list){
                    this.list_user_employee_number = json.result.list;
                }
                else if(json.error){
                    console.error(json.error);
                }
			},
			get_user_employee_number(id){
				var obj = this.list_user_employee_number.getObj({"user_id":id});
				var ret = "";
				if(obj){
					if(obj.nickname){
						ret = obj.nickname;}
					else{
						ret = obj.username;
					}
				}
				return ret;
			},

			/**
			 * 获取对象之前
			 * @param {Object} param
			 */
			get_obj_before(param) {
				var form = "";
				// 获取缓存数据附加
				form = $.db.get("form");
				$.push(this.form ,form);
				/**
				* 请求列表前
				* @param {Object} param
				*/
				var user_group = this.user.user_group;
				if (user_group !== "管理员") {
					switch (user_group) {
						case "职工编号":
							if(param["employee_number"] > 0){
								param["employee_number"] = this.user.user_id;
							}
							break;
					}
				}
				if(this.form && form){
					Object.keys(this.form).forEach(key => {
					Object.keys(form).forEach(dbKey => {
						if(dbKey === "charging_standard"){
							this.form['charging_rules'] = form[dbKey];
							this.disabledObj['charging_rules_isDisabled'] = true;
						};
						if(key === dbKey){
							this.disabledObj[key+'_isDisabled'] = true;
						}
					})
				})
				}
				return param;
			},

			/**
			 * 获取对象之后
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_after(json, func){

			},

			is_view(){
				var bl = this.user_group == "管理员";

				if(!bl){
					bl = this.$check_action('/notification_management/table','add');
					console.log(bl ? "你有表格添加权限视作有添加权限" : "你没有表格添加权限");
				}
				if(!bl){
					bl = this.$check_action('/notification_management/table','set');
					console.log(bl ? "你有表格添加权限视作有修改权限" : "你没有表格修改权限");
				}
				if(!bl){
					bl = this.$check_action('/notification_management/view','add');
					console.log(bl ? "你有视图添加权限视作有添加权限" : "你没有视图添加权限");
				}
				if(!bl){
					bl = this.$check_action('/notification_management/view','set');
					console.log(bl ? "你有视图修改权限视作有修改权限" : "你没有视图修改权限");
				}
				if(!bl){
					bl = this.$check_action('/notification_management/view','get');
					console.log(bl ? "你有视图查询权限视作有查询权限" : "你没有视图查询权限");
				}

				console.log(bl ? "具有当前页面的查看权，请注意这不代表你有字段的查看权" : "无权查看当前页，请注意即便有字段查询权限没有页面查询权限也不行");

				return bl;
			},

			/**
			 * 上传文件
			 * @param {Object} param
			 */
			uploadimg(param) {
				this.uploadFile(param.file, "avatar");
			}

		},
		created() {
			this.get_list_user_employee_number();
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
